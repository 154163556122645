import {
  Button,
  Flex,
  Heading,
  Container,
  Stack,
  Text,
  chakra,
  Box,
  SimpleGrid,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";

import Links from "../components/Links";

export default function SplitScreen() {
  return (
    <Box
      maxW="7xl"
      mx={"auto"}
      py={12}
      px={{ base: 2, sm: 12, md: 17 }}
      mt={20}
    >
      <Stack spacing={{ base: 8, md: 20 }}>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent={"center"}
          gap={10}
        >
          <Flex flex={1} p={0}>
            <Image
              w={"full"}
              alt={"about"}
              src={"about.jpg"}
              border={"5px solid #f4b80c"}
              rounded={"xl"}
              overflow={{ base: "hidden" }}
            />
          </Flex>
          <Flex
            p={{ base: "8", lg: "20" }}
            flex={1}
            align={"center"}
            justify={"center"}
            shadow={"2xl"}
            bg={"blackAlpha.600"}
            border={"5px solid #f4b80c"}
            rounded={"xl"}
            overflow={{ base: "hidden" }}
          >
            <Stack spacing={8} w={"full"} maxW={"lg"}>
              <chakra.h1
                fontSize={{ base: "3xl", md: "5xl", lg: "6xl" }}
                textAlign={"center"}
                fontWeight={"bold"}
              ></chakra.h1>
              <Text
                fontSize={{ base: "md", lg: "2xl" }}
                color={"#fff1e6"}
                textShadow={"2px 2px 0px black"}
                bg={"#f4b80c"}
                border={"2px solid black"}
                p={4}
                rounded={"xl"}
                textAlign={"center"}
              >
                Contract: 0x00000000000000000000000000000000
              </Text>
              <Text
                fontSize={{ base: "md", lg: "2xl" }}
                color={"#fff1e6"}
                textShadow={"2px 2px black"}
              >
                "Chief Happiness Officer: Proof-of-Bark consensus mechanism,
                100% tail-wagging liquidity, zero ruff-grets!"
              </Text>

              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={4}
                justifyContent={"space-evenly"}
              >
                <Button
                  as={"a"}
                  w={"full"}
                  px={10}
                  py={8}
                  color={"#fff1e6"}
                  textShadow={"2px 2px 0px black"}
                  border={"2px solid black"}
                  rounded={"md"}
                  fontWeight={"bold"}
                  fontSize={"lg"}
                  _hover={{ color: "fff1e6" }}
                  bg={"#f4b80c"}
                  target="_blank"
                  href={"https://x.com/Real_DonaldGoph"}
                >
                  Twitter
                </Button>
                <Button
                  color={"black"}
                  textShadow={"2px 2px #fff1e6"}
                  border={"2px solid black"}
                  rounded={"lg"}
                  px={10}
                  py={8}
                  fontSize={"lg"}
                  w={"full"}
                  as={"a"}
                  _hover={{ color: "#f02928" }}
                  target="_blank"
                  href={"https://t.me/cho444444"}
                >
                  Telegram
                </Button>
              </Stack>
            </Stack>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  );
}
