import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Button,
  useColorModeValue,
  useBreakpointValue,
  Flex,
  Tag,
  Image,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaTelegram, FaTwitter, FaDiscord } from "react-icons/fa";
import Links from "./Links";
const Logo = (props: any) => {
  return <Image w={"24"} src={"logo192.png"} alt={"Logo"} {...props} />;
};

const SocialButton = ({
  children,
  label,
  href,
  target,
}: {
  children: ReactNode;
  label: string;
  href: string;
  target: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={10}
      h={10}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target={target}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoCentered() {
  return (
    <Box
      bg={"#2aaffc"}
      bgImage={"url('hero-items-bg.jpg')"}
      color={"gray.200"}
      textShadow={"2px 2px black"}
      borderTop={"3px solid black"}
      borderBottom={"3px solid black"}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={"center"}>
            <Link
              fontWeight={"500"}
              fontSize={"lg"}
              mb={2}
              target="_blank"
              href={"https://x.com/Real_DonaldGoph"}
            >
              TWITTER
            </Link>
          </Stack>
          <Stack align={"center"}>
            <Link
              fontWeight={"500"}
              fontSize={"lg"}
              mb={2}
              target="_blank"
              href={"https://t.me/cho444444"}
            >
              TELEGRAM
            </Link>
          </Stack>
          <Stack align={"center"}>
            <Link
              fontWeight={"500"}
              fontSize={"lg"}
              mb={2}
              href={"https://etherscan.io/token/0x"}
              target="_blank"
            >
              CONTRACT
            </Link>
          </Stack>
          <Stack align={"center"}>
            <Link
              fontWeight={"500"}
              fontSize={"lg"}
              mb={2}
              href={
                "https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x&chain=ethereum"
              }
              target="_blank"
            >
              UNISWAP
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box pb={4}>
        <Text fontSize={"lg"} textAlign={"center"}>
          © 2024 Chief Happiness Officer. All rights reserved.
        </Text>
      </Box>
    </Box>
  );
}
