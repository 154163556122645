import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Image,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const Logo = (props: any) => {
    return (
      <Box display={{ base: "none", md: "inline-flex" }}>
        <Image
          src={"./logo192.jpg"}
          h={"50px"}
          rounded={"xl"}
          border={"2px solid #f4b80c"}
        ></Image>
      </Box>
    );
  };
  return (
    <Box
      w={"full"}
      position={"fixed"}
      top={0}
      // backdropFilter={{ base: "blur(5px)" }}
      bg={"#f4b80c"}
      bgImage={"hero-items-bg.jpg"}
      borderBottom={"2px solid black"}
      zIndex={10}
    >
      <Flex
        mx={"auto"}
        maxW={"7xl"}
        w={"full"}
        h={"70px"}
        color={"#fff1e6"}
        py={{ base: 2, md: 6 }}
        px={{ base: 4 }}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            color={"#fff1e6"}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Logo fontSize={"sm"} />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
          <Button
            as={"a"}
            display={{ base: "inline-flex" }}
            fontSize={{ base: "sm", md: "md" }}
            href={"#"}
            color={"black"}
            // textShadow={"2px 2px 0px black"}
            bg={"#f4b80c"}
            border={"2px solid black"}
            // shadow={"0 0 10px 0px #2aaffc"}
            onClick={() => {
              window.open(
                "https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x&chain=ethereum"
              );
            }}
          >
            Buy Now
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = "#fff1e6";

  const popoverContentBgColor = "gray.800";

  return (
    <Stack direction={"row"} spacing={4} alignItems={"center"}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                px={4}
                href={navItem.href ?? "#"}
                fontSize={{ base: "sm", md: "xl" }}
                fontWeight={500}
                isExternal
                color={linkColor}
                textShadow={"2px 2px 0px black"}
                _hover={{
                  color: "#f4b80c",
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{
        bg: "gray.900",
      }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={{ base: "sm", md: "xl" }}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack bg={"#1775d7"} p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
      ``
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        zIndex={10}
        textShadow={"2px 2px 0px black"}
        target="_blank"
      >
        <Text fontWeight={600} color={"gray.200"}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={"gray.700"}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Twitter",
    href: "https://x.com/Real_DonaldGoph",
  },
  {
    label: "Telegram",
    href: "https://t.me/cho444444",
  },
  {
    label: "Chart",
    href: "https://dexscreener.com/ethereum/0x",
  },
  {
    label: "Contract",
    href: "https://etherscan.io/token/0x",
  },
];
