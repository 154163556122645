"use client";

import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";

interface CardProps {
  heading: string;
  description: string;
  imgUrl: string;
}

export default function CallToActionWithVideo() {
  return (
    <Container maxW={"7xl"} pt={"100px"} zIndex={1}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack
          flex={1}
          spacing={{ base: 5, md: 10 }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Heading lineHeight={1.1} fontWeight={600} fontFamily={"kom"}>
            <Text
              fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
              as={"span"}
              color={"#f4b80c"}
              p={2}
              rounded={"lg"}
              textShadow={"3px 3px black"}
            >
              $CHO
            </Text>
          </Heading>
          <Text
            color={"#f4b80c"}
            textShadow={"3px 3px black"}
            fontSize={{ base: "xl", sm: "2xl", lg: "3xl" }}
          >
            Chief Happiness Officer
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
            alignItems={"center"}
          >
            <Button
              color={"black"}
              // textShadow={"2px 2px 0px black"}
              border={"2px solid black"}
              rounded={"md"}
              fontWeight={"bold"}
              fontSize={"lg"}
              _hover={{ color: "f4b80c" }}
              px={10}
              py={8}
              bg={"#f4b80c"}
              onClick={() => {
                window.open(
                  "https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x&chain=ethereum"
                );
              }}
            >
              Buy Now
            </Button>
            <Image
              src={"./chart.png"}
              h={"50px"}
              rounded={"xl"}
              _hover={{
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://dexscreener.com/ethereum/0x");
              }}
            ></Image>
            <Image
              src={"./x.png"}
              h={"50px"}
              rounded={"xl"}
              _hover={{
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://x.com/Real_DonaldGoph");
              }}
            ></Image>
            <Image
              src={"./telegram.png"}
              h={"50px"}
              rounded={"xl"}
              _hover={{
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://t.me/cho444444");
              }}
            ></Image>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Box
            position={"relative"}
            // height={"300px"}
            rounded={"2xl"}
            border={"6px solid #f4b80c"}
            boxShadow={"2xl"}
            width={"full"}
            overflow={"hidden"}
          >
            <Image
              alt={"Hero Image"}
              align={"center"}
              w={"100%"}
              src={"/hero-img.jpg"}
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}
