import {
  Box,
  chakra,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Container,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import Links from "./Links";

interface StatsCardProps {
  title: string;
  stat: string;
}
function StatsCard(props: StatsCardProps) {
  const { title, stat } = props;
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={"5"}
      textAlign={{ base: "center", md: "left" }}
      color={"whiteAlpha.900"}
      textShadow={"2px 2px black"}
      bg={
        "linear-gradient(90deg,rgba(255, 0, 0, 1) 0%,rgba(255, 154, 0, 1) 10%,rgba(208, 222, 33, 1) 20%,rgba(79, 220, 74, 1) 30%,rgba(63, 218, 216, 1) 40%,rgba(47, 201, 226, 1) 50%,rgba(28, 127, 238, 1) 60%,rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%)"
      }
    >
      <StatLabel
        fontWeight={"medium"}
        textAlign={{ base: "center" }}
        isTruncated
        fontSize={{ base: "md", lg: "2xl" }}
      >
        {title}
      </StatLabel>
      <StatNumber
        textAlign={{ base: "center" }}
        fontSize={{ base: "xl", sm: "xl", md: "xl" }}
        fontWeight={"medium"}
        isTruncated
      >
        {stat}
      </StatNumber>
    </Stat>
  );
}

function SplitScreen() {
  return (
    <Stack
      direction={{ base: "column" }}
      shadow={"2xl"}
      bg={"blackAlpha.300"}
      rounded={"xl"}
      overflow={{ base: "hidden" }}
      border={"3px solid #f4b80c"}
      gap={0}
    >
      <Flex flex={1}>
        <Image
          w={"full"}
          alt={"tokenomics"}
          objectFit={"cover"}
          src={"tokenomics-img2.jpg"}
        />
      </Flex>
    </Stack>
  );
}

export default function BasicStatistics() {
  return (
    <Box maxW="7xl" mx={"auto"} py={12} px={{ base: 2, sm: 12, md: 17 }}>
      <Stack spacing={{ base: 8, md: 20 }}>
        <chakra.h1
          fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
          textAlign={"center"}
          mt={10}
          fontWeight={"bold"}
        >
          <Text
            color={"#fff1e6"}
            as={"span"}
            position={"relative"}
            textShadow={"2px 2px black"}
            _after={{
              content: "''",
              width: "full",
              height: useBreakpointValue({ base: "20%", md: "30%" }),
              position: "absolute",
              bottom: 1,
              left: 0,
              bg: "#2aaffc",
              shadow: "0 0 10px 0px #2aaffc",
              zIndex: -1,
            }}
          >
            "Warning: Chief Happiness Officer causes instant mood pumps. No
            dumps, only jumps (for treats)!"{" "}
          </Text>
        </chakra.h1>
        <SplitScreen></SplitScreen>
      </Stack>
    </Box>
  );
}
