import * as React from "react";
import { ChakraProvider, theme, Box } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import TokenInfo from "./components/TokenInfo";
import Footer from "./components/Footer";
import { Fonts } from "./Fonts";
export const App = () => (
  <ChakraProvider theme={theme}>
    <Fonts></Fonts>
    <Box
      w={"full"}
      overflow={"hidden"}
      fontFamily={"kom"}
      bgImage={"texture.jpg"}
      // bgRepeat="no-repeat"
      bgAttachment={"fixed"}
      // bgSize={"cover"}
    >
      <Navbar></Navbar>
      <Hero></Hero>
      <About></About>
      <TokenInfo></TokenInfo>
      {/* <Roadmap></Roadmap> */}
      <Footer></Footer>
    </Box>
  </ChakraProvider>
);
