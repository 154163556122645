import * as React from "react";
import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'kom';
        font-style: normal;
        font-weight: 700;
        src: url(KOM.woff2) format('woff2');
      }
      `}
  />
);
